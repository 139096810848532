<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Info Turno</label>
        <img src="../../assets/img/icon/close_popup.png" @click="cerrar()" />
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label">Vigilancia</label>
          <label>{{ turno.vigilancia.nombre }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label">Horario de turno</label>
          <label class="popup-data">
            <div class="turno-hora-div"></div>
            <div>{{ getHora(turno.hora_inicio) }}</div>
            <div>
              {{
                turno.hora_fin != null ? getHora(turno.hora_fin) : "en curso"
              }}
            </div>
          </label>
        </div>
        <div class="popup-row">
          <label class="popup-label">Integrantes</label>
          <div v-for="integrante in turno.integrantes" :key="integrante">
            <label>{{ integrante }}</label>
          </div>
        </div>
        <div class="popup-row">
          <label class="popup-label">Mensajes</label>
          <label class="popup-data"
            >{{ turno.mensajes.length }} Mensajes emitidos</label
          >
        </div>
      </div>
      <div class="popup-bot-border" v-show="turno.finalizar">
        <button
          class="button button-primary popup-btn-continuar-chico"
          @click="finalizar()"
        >
          Finalizar
        </button>
      </div>
    </div>
    <Finalizar
      :mostrar="mostrarFinalizar"
      :tipo="'Turno'"
      :nombre="turno.vigilancia.nombre"
      @cerrar="mostrarFinalizar = false"
      @aceptar="finalizarAceptar()"
    ></Finalizar>
  </div>
</template>

<script>
import Finalizar from "@/components/Finalizar.vue";

export default {
  name: "Info_Turno",
  components: { Finalizar },
  props: {
    turno: Object,
  },
  data() {
    return {
      mostrarFinalizar: false,
      guardando: false,
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      dias: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    };
  },
  mounted() {},
  methods: {
    editar() {
      this.$emit("editar", this.turno);
      this.cerrar();
    },
    cerrar() {
      this.$emit("cerrar");
    },
    getHora(hora) {
      if (hora != null) {
        let d = new Date(hora);
        return (
          this.dias[d.getDay()] +
          " " +
          d.getDate() +
          " " +
          this.meses[d.getMonth()] +
          ", " +
          hora.split("T")[1].substr(0, 5)
        );
      }
    },
    finalizar() {
      this.mostrarFinalizar = true;
    },
    finalizarAceptar() {
      this.mostrarFinalizar = false;
      var that = this;
      this.$axios
        .put(this.$serverURL + "/api/turnos/finalizar/" + this.turno.id + "/")
        .then(function (response) {
          console.log(response.data);
          that.$emit("finalizar");
        })
        .catch(function (response) {
          console.log("finalizar", response);
        });
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/turnos.scss"></style>
