<template>
  <div class="view">
    <Topbar :seccion="'turnos'"></Topbar>
    <div class="view-container">
      <div class="secciones-top">
        <label class="secciones-title">Turnos</label>
        <Dropdown
          class="dropdown"
          :options="vigilancias"
          :placeholder="'Todas las vigilancias'"
          :paraArriba="false"
          :conInput="false"
          :name="'nombre'"
          v-on:updateOption="updateDropdownVigilancia"
        ></Dropdown>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../assets/img/icon/close.png"
            @click="searchText = ''"
          />
          <img class="secciones-buscar-img" src="../assets/img/icon/lupa.png" />
        </div>
      </div>
      <div
        class="turnos-vigente"
        v-for="(turno, index) in turnosVigentesFiltrados"
        :key="index"
      >
        <img src="../assets/img/icon/turno.png" />
        <div>
          <label class="turnos-vigente-titulo"
            >Turno Vigente
            {{
              turno.vigilancia != null ? turno.vigilancia.nombre : "-"
            }}</label
          >
          <label class="turnos-vigente-subtitulo">
            <div class="turno-hora-div"></div>
            <div>{{ getHora(turno.hora_inicio) }}</div>
            <div>
              {{
                turno.hora_fin != null ? getHora(turno.hora_fin) : "en curso"
              }}
            </div>
          </label>
          <label class="turnos-vigente-integrantes"
            >{{ turno.integrantes.length }} Integrantes</label
          >
          <div class="turnos-vigente-mensajes">
            <label>{{ turno.mensajes.length }}</label>
            <img src="../assets/img/icon/email.png" />
          </div>
          <button
            class="button button-primary"
            style="float: right"
            @click="finalizarTurno(turno)"
          >
            Finalizar
          </button>
        </div>
      </div>
      <div class="secciones-turnos">
        <div class="secciones-turnos">
          <div
            v-for="turno in turnosFiltrados"
            :key="turno.id"
            class="turnos-turno"
            @click="elegirTurno(turno)"
          >
            <img class="turno-img" src="../assets/img/icon/turno.png" />
            <div>
              <label class="turno-nombre">{{
                turno.vigilancia != null ? turno.vigilancia.nombre : "-"
              }}</label>
              <label class="turno-hora">
                <div class="turno-hora-div"></div>
                <div>{{ getHora(turno.hora_inicio) }}</div>
                <div>
                  {{
                    turno.hora_fin != null
                      ? getHora(turno.hora_fin)
                      : "en curso"
                  }}
                </div>
              </label>
              <label class="turnos-vigente-integrantes"
                >{{ turno.integrantes.length }} Integrantes</label
              >
              <div class="turno-mensajes">
                <label>{{ turno.mensajes.length }}</label>
                <img src="../assets/img/icon/email.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Info
      v-if="mostrarInfo"
      :turno="turnoElegido"
      @cerrar="cerrarInfo()"
      @editar="editar"
      @finalizar="getTurnosVigentes()"
      @get="getTurnos()"
    ></Info>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Info from "@/components/turnos/Info.vue";
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "Turnos",
  components: { Topbar, Info, Dropdown },
  data() {
    return {
      searchText: "",
      turnosVigentes: [],
      turnosVigentesFiltrados: [],
      turnos: [],
      turnosFiltrados: [],
      vigilancias: [],
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      dias: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      mostrarNuevo: false,
      mostrarInfo: false,
      turnoElegido: null,
      opcion: null,
    };
  },
  mounted() {
    this.getTurnos();
    this.getTurnosVigentes();
    this.getVigilancias();
  },
  methods: {
    updateDropdownVigilancia(payload) {
      if (payload.nombre == "Todas") {
        this.filtroVigilancia = null;
      } else {
        this.filtroVigilancia = payload.id;
      }
      this.filtrar();
    },
    cerrarNuevo() {
      this.mostrarNuevo = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    elegirTurno(turno) {
      this.turnoElegido = turno;
      this.mostrarInfo = true;
    },
    setDesde(desde) {
      console.log(desde);
    },
    setHasta(hasta) {
      console.log(hasta);
    },
    editar(turno) {
      this.mostrarNuevo = true;
      this.$emitter.emit("turnos-editar", turno);
    },
    getHora(hora) {
      if (hora != null) {
        let d = new Date(hora);
        return (
          this.dias[d.getDay()] +
          " " +
          d.getDate() +
          " " +
          this.meses[d.getMonth()] +
          ", " +
          hora.split("T")[1].substr(0, 5)
        );
      }
    },
    getTurnos() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/turnos/all/")
        .then(function (response) {
          console.log(response);
          that.turnos = response.data;
          that.turnosFiltrados = that.turnos;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getTurnosVigentes() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/turnos/activo/")
        .then(function (response) {
          console.log("turnosVigentes", response);
          that.turnosVigentes = response.data;
          that.turnosVigentesFiltrados = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          that.vigilancias = response.data;
          that.vigilancias.push({ id: true, nombre: "En vía pública" });
          that.vigilancias.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    finalizarTurno(turno) {
      turno.finalizar = true;
      this.elegirTurno(turno);
    },
    filtrar() {
      let filtroBuscar = this.searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      this.turnosFiltrados = this.turnos.filter(
        (e) =>
          (this.filtroVigilancia == null ||
            (e.vigilancia != null &&
              e.vigilancia.id == this.filtroVigilancia) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              this.filtroVigilancia == true)) &&
          (this.searchText == "" ||
            (e.vigilancia != null &&
              e.vigilancia.nombre.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (e.vigilancia != null &&
              e.vigilancia.empresa != null &&
              e.vigilancia.empresa.nombre.toLowerCase().indexOf(filtroBuscar) >
                -1) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              "En via publica".indexOf(filtroBuscar) > -1))
      );
      this.turnosVigentesFiltrados = this.turnosVigentes.filter(
        (e) =>
          (this.filtroVigilancia == null ||
            (e.vigilancia != null &&
              e.vigilancia.id == this.filtroVigilancia) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              this.filtroVigilancia == true)) &&
          (this.searchText == "" ||
            (e.vigilancia != null &&
              e.vigilancia.nombre.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (e.vigilancia != null &&
              e.vigilancia.empresa != null &&
              e.vigilancia.empresa.nombre.toLowerCase().indexOf(filtroBuscar) >
                -1) ||
            (e.vigilancia == null &&
              e.ubicacion_alternativa != null &&
              "En via publica".indexOf(filtroBuscar) > -1))
      );
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
<style scoped lang="scss" src="@/assets/css/views/turnos.scss"></style>
